import { useEffect, useState } from 'react';

function useMarkdownDocument(documentName: string | undefined) {
  const [markdownText, setMarkdownText] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);

    import(`../docs/md/${documentName}`)
      .then((module) => {
        fetch(module.default)
          .then((response) => {
            if (!response.ok) {
              throw new Error('Failed to fetch markdown content');
            }
            return response.text();
          })
          .then((text) => {
            setMarkdownText(text);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            setError(true);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(true);
      });
  }, [documentName]);

  return { markdownText, loading, error };
}

export default useMarkdownDocument;
