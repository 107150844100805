import React, { useState } from 'react';
import {
  Accordion,
  Badge,
  Button,
  Center,
  createStyles,
  Input,
  Loader,
  Space,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { IconAccessPoint, IconChevronsRight } from '@tabler/icons-react';
import ReactMarkdown from 'react-markdown';
import { NavLink, useLocation } from 'react-router-dom';
import { projects, TechBadge } from './data';
import ProjectLabel from './ProjectLabel';

const content = `
# 👨🏻‍💻 Projects 

This is a list of projects I have been involved in. Some of them have a live demo link: feel free to check them out and contact me if you have any questions.

`;

const useStyles = createStyles((theme, _params, getRef) => ({
  projectContainer: {
    marginTop: theme.spacing.md,
    marginBottom: theme.spacing.md,
    [theme.fn.smallerThan(770)]: {
      alignItems: 'center',
    },
  },

  introBox: {
    minWidth: '60%',
    margin: theme.spacing.md,
  },

  projectAccessButtonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  projectAccessButtonStack: {
    display: 'flex',
    flexDirection: 'column',
  },

  projectFooterBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  projectItem: {
    margin: '0.5rem 0',
  },

  projectBrefCard: {
    width: '45%',
    padding: '0.5rem',
    margin: '0.5rem',

    [theme.fn.smallerThan(770)]: {
      width: '90%',
    },
  },
}));

export default function Projects() {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [projectsList, setProjectList] = useState(() => projects);
  const [openedAccordion, setOpenedAccordion] = useState<string | null>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const accordionId = queryParams.get('p');

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectList(
      projects.filter((project) =>
        project.title.toLowerCase().includes(e.target.value.toLowerCase()),
      ),
    );
    setSearchKeyword(e.target.value);
  };

  const projectItems = projectsList.map((project) => {
    return (
      <>
        <Accordion.Item value={project.id} key={project.title}>
          <Accordion.Control>
            <ProjectLabel project={project} searchKeyword={searchKeyword}>
              <Text size="sm" weight={400} lineClamp={2}>
                {project.description}
              </Text>
              <Text size="sm" color="dimmed" weight={400}>
                {project.date}
              </Text>
            </ProjectLabel>
          </Accordion.Control>
          <Accordion.Panel>
            <Text size="sm">{project.details}</Text>

            <Space h="xl" />
            <div className={classes.projectFooterBox}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {project.tag.map((tag, tagIdx) => (
                  <Badge
                    key={tagIdx}
                    style={{ marginRight: '5px', marginTop: '5px' }}
                    color={TechBadge.find((t) => t.name === tag)?.color}
                    variant="light"
                    radius="xl"
                  >
                    {tag}
                  </Badge>
                ))}
              </div>
            </div>
            <Space h="xl" />
            <div className={classes.projectAccessButtonGroup}>
              <div className={classes.projectAccessButtonStack}>
                {project.demo && (
                  <a href={project.demo} target="_blank" rel="noreferrer">
                    <Button
                      variant="subtle"
                      size="xs"
                      rightIcon={<Loader size="xs" variant="bars" />}
                    >
                      Live Demo
                      {/* <IconArrowBigRightLineFilled
                        size={15}
                        style={{ marginLeft: '5px' }}
                      /> */}
                    </Button>
                  </a>
                )}
                {project.doc && (
                  <NavLink to={`/projects/${project.id}`}>
                    {/* TODO */}
                    <Button
                      variant="subtle"
                      size="xs"
                      rightIcon={<IconChevronsRight size={18} />}
                    >
                      Know more
                    </Button>
                  </NavLink>
                )}
              </div>
            </div>
          </Accordion.Panel>
        </Accordion.Item>
        <Space h="xs" />
      </>
    );
  });

  return (
    <>
      <ReactMarkdown className={classes.introBox} children={content} />
      <Center>
        <Input
          style={{ width: '80%', margin: '20px' }}
          placeholder="Type here to filter by project name ..."
          onChange={handleFilterChange}
        />
      </Center>

      <Accordion
        chevronPosition="right"
        variant="filled"
        defaultValue={accordionId}
      >
        {projectItems}
      </Accordion>
    </>
  );
}
