import React, { useState } from 'react';
import {
  Group,
  Avatar,
  Text,
  Space,
  Skeleton,
  Highlight,
  Badge,
  Center,
  Flex,
  Box,
  createStyles,
  rem,
} from '@mantine/core';
import NullImagePlaceHolder from '../../components/logo/NullImagePlaceHolder';
import { Project } from './data';

// const useStyles = createStyles((theme) => ({
//   cardBadge: {
//     position: 'absolute',
//     top: '-10px',
//     right: '-10px',
//     pointerEvents: 'none',
//     zIndex: 999,
//   },
// }));

interface AccordionLabelProps {
  project: Project;
  searchKeyword?: string;
  children?: React.ReactNode;
}

export default function ProjectLabel({
  project,
  searchKeyword,
  children,
}: AccordionLabelProps) {
  const [isAvatarLoaded, setIsAvatarLoaded] = useState(!project.thumbnail);
  // const { classes } = useStyles();

  return (
    <Group noWrap>
      {!isAvatarLoaded && <Skeleton width={56} height={56} />}
      <Avatar
        style={isAvatarLoaded ? {} : { display: 'none' }}
        src={project.thumbnail}
        onLoad={() => {
          setIsAvatarLoaded(true);
        }}
        size="lg"
      >
        {!project.thumbnail && <NullImagePlaceHolder />}
      </Avatar>

      <div>
        <Flex direction="column">
          <Flex>
            <Text size="md" weight={500}>
              <Highlight highlight={searchKeyword ?? ''}>
                {project.title}
              </Highlight>
            </Text>

            {project.badge &&
              project.badge.map((label, idx) => {
                return (
                  <Center>
                    <Badge
                      key={idx}
                      // className={classes.cardBadge}
                      style={{ marginLeft: '5px' }}
                      size="sm"
                      variant="gradient"
                      gradient={{ from: 'indigo', to: 'cyan' }}
                    >
                      {label}
                    </Badge>
                  </Center>
                );
              })}
          </Flex>
          {children}
        </Flex>
      </div>
    </Group>
  );
}
