import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Header, createStyles } from '@mantine/core';
import ToggleThemeButton from '../ToggleThemeButton';

const useStyles = createStyles((theme, _params, getRef) => ({
  linkItemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxHeight: '850px',
    maxWidth: '900px',
    margin: 'auto',

    [theme.fn.smallerThan(620)]: {
      fontSize: '0.9rem',
      justifyContent: 'center',
      padding: '0 0.2rem',
    },
  },

  navLinksWrapper: {
    display: 'flex',
    marginLeft: '40px',
    [theme.fn.smallerThan(620)]: {},
  },

  navLinks: {
    display: 'block',
    padding: '0.6rem',
    margin: '0 0.6rem',
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[1],
    },

    [theme.fn.smallerThan(620)]: {
      margin: '0 0.3rem',
    },
  },

  activeNavLinks: {
    display: 'block',
    padding: '0.6rem',
    margin: '0 0.6rem',
    textDecoration: 'none',
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[1],
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    [theme.fn.smallerThan(620)]: {
      margin: '0 0.3rem',
    },
  },

  themeButton: {
    marginRight: '40px',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[1],
    },
  },
}));

const headers = [
  // {
  //   title: 'Home',
  //   to: '/',
  // },
  {
    title: 'About',
    to: '/',
  },
  {
    title: 'Projects',
    to: '/projects',
  },
  {
    title: 'Contact',
    to: '/contact',
  },
];

export default function NavHeader() {
  const location = useLocation();
  const { classes } = useStyles();

  return (
    <Header className={classes.linkItemWrapper} height={70}>
      <div className={classes.navLinksWrapper}>
        {headers.map((header, idx) => {
          return location.pathname === header.to ? (
            <NavLink
              key={idx}
              to={header.to}
              className={classes.activeNavLinks}
            >
              {header.title}
            </NavLink>
          ) : (
            <NavLink to={header.to} className={classes.navLinks}>
              {header.title}
            </NavLink>
          );
        })}
      </div>
      <div className={classes.themeButton}>
        <ToggleThemeButton />
      </div>
    </Header>
  );
}
