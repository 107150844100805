import {
  createStyles,
  Paper,
  Flex,
  Box,
  Badge,
  Text,
  rem,
  Indicator,
} from '@mantine/core';
import { IconChevronsRight } from '@tabler/icons-react';
import React from 'react';
import ProjectLabel from '../pages/projects/ProjectLabel';
import { Project, TechBadge } from '../pages/projects/data';

const useStyles = createStyles((theme) => ({
  card: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    padding: theme.spacing.xl,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    '&:hover': {
      boxShadow: theme.shadows.md,
      transform: 'scale(1.025)',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
    },
  },
}));

interface Props {
  proj: Project;
}

export default function ProjectPaperItem(props: Props) {
  const { proj } = props;
  const { classes } = useStyles();

  return (
    <Paper
      className={classes.card}
      withBorder
      style={{ margin: '10px' }}
      shadow="sm"
      radius="lg"
      p="md"
    >
      <Flex justify="space-between" align="center">
        <ProjectLabel key={proj.title} project={proj}>
          <Box style={{ marginBottom: rem(1) }}>
            <Text size="sm" weight={400} lineClamp={2}>
              {proj.description}
            </Text>
          </Box>
          <Text size="sm" color="dimmed" weight={400}>
            {proj.date}
          </Text>
        </ProjectLabel>
        <Box style={{ margin: '5px' }}>
          <IconChevronsRight color="gray" size={28} />
        </Box>
      </Flex>
      <Flex style={{ paddingTop: '10px' }}>
        {proj.tag.map((tag, idx) => (
          <Badge key={idx} color={TechBadge.find((t) => t.name === tag)?.color}>
            {tag}
          </Badge>
        ))}
      </Flex>
    </Paper>
  );
}
