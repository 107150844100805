import React, { useState } from 'react';
import {
  Avatar,
  Center,
  createStyles,
  Group,
  Paper,
  Skeleton,
  Text,
  useMantineTheme,
  Image,
  Box,
  rem,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  card: {
    minWidth: '40%',
    margin: '10px',
    position: 'relative',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    padding: theme.spacing.xl,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    [theme.fn.smallerThan(770)]: {
      width: '90%',
    },

    '&:hover': {
      boxShadow: theme.shadows.md,
      transform: 'scale(1.025)',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
    },
  },

  cardItem: {
    padding: theme.spacing.md,
  },

  cardAvatar: {
    margin: theme.spacing.xl,
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '10px',
    borderColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[1]
        : theme.colors.gray[4],
  },
}));

export default function AvatarCard() {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const [isIconLoaded, setIsIconLoaded] = useState(false);

  return (
    <Paper className={classes.card} shadow="md" p="xl" radius="xl">
      <Center>
        {!isIconLoaded && (
          <Skeleton className={classes.cardAvatar} circle height={240} />
        )}
        <div style={isIconLoaded ? {} : { display: 'none' }}>
          <Box className={classes.cardAvatar}>
            <Image
              onLoad={() => {
                setIsIconLoaded(true);
              }}
              radius={1000}
              height={240}
              width={240}
              src="./images/self-intro-1.png"
            />
          </Box>
        </div>
      </Center>
      <Center>
        <Text size="xl" weight={700}>
          Johnny Chiu
        </Text>
      </Center>
      <Center>
        <Text
          style={{ margin: theme.spacing.xs }}
          ta="center"
          c="dimmed"
          fz="sm"
        >
          jhhchiu@gmail.com • Software Engineer
        </Text>
      </Center>
      <Center>
        <div style={{ backgroundColor: theme.colorScheme }}>🇭🇰 ✈️ 🇨🇦</div>
      </Center>
    </Paper>
  );
}
