import React from 'react';
import { Skeleton } from '@mantine/core';

export default function DocumentSkeleton() {
  return (
    <>
      <Skeleton height={20} mt={18} width="50%" radius="xl" />
      <Skeleton height={18} mt={18} width="70%" radius="xl" />
      <Skeleton height={18} mt={18} radius="xl" />
      <Skeleton height={18} mt={18} width="70%" radius="xl" />
      <Skeleton height={18} mt={18} radius="xl" />
      <Skeleton height={18} mt={18} width="70%" radius="xl" />
      <Skeleton height={18} mt={18} radius="xl" />
      <Skeleton height={18} mt={18} width="70%" radius="xl" />
      <Skeleton height={18} mt={18} width="70%" radius="xl" />
      <Skeleton height={18} mt={18} radius="xl" />
      <Skeleton height={18} mt={18} width="70%" radius="xl" />
      <Skeleton height={18} mt={18} width="70%" radius="xl" />
      <Skeleton height={18} mt={18} radius="xl" />
      <Skeleton height={18} mt={18} width="70%" radius="xl" />
    </>
  );
}
