import { IconError404 } from '@tabler/icons-react';
import React from 'react';

export interface Props {
  icon?: React.ReactNode;
  reason?: string;
}
export default function NotFound({
  reason = 'Not Found',
  icon = <IconError404 size={100} />,
}: Props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: '15vh',
      }}
    >
      {icon && <div>{icon}</div>}
      <div
        style={{
          color: 'linear-gradient(#cccccc, #add8e6)',
          fontFamily: 'Courier New, monospace',
          textAlign: 'center',
          padding: '10px',
          fontSize: '20px',
        }}
      >
        {reason}
      </div>
    </div>
  );
}
