import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import ProjectDetails from './pages/projects/ProjectDetails';
import Projects from './pages/projects/Projects';

export default function AppRouter() {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />

      {/* <Route path="/" element={<Home />} /> */}
      <Route path="/" element={<About />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/projects/:projectName" element={<ProjectDetails />} />

      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}
