import React from 'react';
import {
  ActionIcon,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { IconMoon, IconSun } from '@tabler/icons-react';

export default function ToggleThemeButton() {
  const theme = useMantineTheme();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === 'dark';

  return (
    <ActionIcon
      variant="transparent"
      radius="xl"
      onClick={() => toggleColorScheme()}
      size="xl"
    >
      {dark ? (
        <ActionIcon variant="transparent">
          <IconMoon size={20} />
        </ActionIcon>
      ) : (
        <ActionIcon color="yellow" variant="transparent">
          <IconSun size={20} />
        </ActionIcon>
      )}
    </ActionIcon>
  );
}
