import { Badge, Center } from '@mantine/core';
import React from 'react';
import { TechBadge, TechTags } from '../pages/projects/data';

interface BadgeListProps {
  badgeList: TechTags[];
}

export default function BadgeList({ badgeList }: BadgeListProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {badgeList.map((tag, tagIdx) => (
        <Badge
          key={tagIdx}
          style={{
            marginRight: '5px',
            marginTop: '5px',
          }}
          color={TechBadge.find((t) => t.name === tag)?.color}
          variant="light"
          radius="xl"
        >
          {tag}
        </Badge>
      ))}
    </div>
  );
}
