import React, { useState } from 'react';
import {
  AppShell,
  ColorScheme,
  ColorSchemeProvider,
  createStyles,
  MantineProvider,
} from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './AppRouter';
import NavFooter from './components/layout/Footer';
import NavHeader from './components/layout/Header';

export default function Application() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');

  const useStyles = createStyles((theme, _params, getRef) => ({
    appShell: {
      maxWidth: '900px',
      margin: 'auto',
    },
  }));

  const { classes } = useStyles();

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  return (
    <Router>
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          theme={{ colorScheme, primaryColor: 'blue' }}
          withGlobalStyles
          withNormalizeCSS
        >
          <AppShell
            fixed
            className={classes.appShell}
            header={<NavHeader />}
            footer={<NavFooter />}
          >
            <Notifications position="top-center" />
            <AppRouter />
          </AppShell>
        </MantineProvider>
      </ColorSchemeProvider>
    </Router>
  );
}
