import React, { useState } from 'react';
import {
  Button,
  Center,
  List,
  Loader,
  Modal,
  PasswordInput,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  IconBrandGithub,
  IconBrandLinkedin,
  IconFile,
  IconMailForward,
} from '@tabler/icons-react';
import ClipboardCopy from '../components/ClipboardCopy';

export default function Contact() {
  const [openPinModal, setOpenPinModal] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  return (
    <div>
      <Modal
        centered
        opened={openPinModal}
        withCloseButton={false}
        onClose={() => setOpenPinModal(false)}
        closeOnClickOutside={!isLoadingFile}
      >
        <form
          style={{
            padding: '5px',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <PasswordInput style={{ minWidth: '60%' }} placeholder="PIN" />
          <Button
            variant="light"
            style={{ minWidth: '20%' }}
            disabled={isLoadingFile}
            type="submit"
            onClick={() => {
              setIsLoadingFile(true);

              setTimeout(() => {
                showNotification({
                  id: 'load-resource',
                  message: 'Oops... Failed to access the specified resource.',
                  autoClose: 2000,
                  withCloseButton: false,
                  color: 'red',
                  styles: (theme) => ({
                    title: { color: theme.colors.dark[1] },
                  }),
                });
                setOpenPinModal(false);
                setIsLoadingFile(false);
              }, 1000);
            }}
          >
            <div style={{ width: '50px' }}>
              <Center>
                {isLoadingFile ? <Loader size="sm" color="white" /> : 'view'}
              </Center>
            </div>
          </Button>
        </form>
      </Modal>
      {/* <ReactMarkdown children={content} /> */}
      <h1>Contact </h1>
      <List withPadding icon={<IconMailForward />}>
        <List.Item>
          <ClipboardCopy title="Email" text="jhhchiu@gmail.com" />
        </List.Item>
        <List.Item icon={<IconBrandGithub />}>
          <strong>Github: </strong>
          <a href="https://github.com/pang0103">pang0103</a>
        </List.Item>
        <List.Item icon={<IconBrandLinkedin />}>
          <strong>Linkedin: </strong>
          <a href="https://www.linkedin.com/in/johnny-chiu-b59135168">
            pang0103
          </a>
        </List.Item>
      </List>

      <h1>Documents📜</h1>
      <List withPadding icon={<IconFile />}>
        <List.Item>
          <strong>Resume: </strong>
          <a href="#" onClick={() => setOpenPinModal(true)}>
            view
          </a>
        </List.Item>
        {/* <List.Item>
          <Button
            style={{ margin: '0 5px' }}
            size="sm"
            color="gray"
            compact
            variant="subtle"
            onClick={() => setOpenPinModal(true)}
          >
            view
          </Button>
        </List.Item> */}
      </List>
    </div>
  );
}
