enum TechTags {
  ReactJs = 'React',
  JavaScript = 'JavaScript',
  TypeScript = 'TypeScript',
  NodeJS = 'Node JS',
  NestJs = 'Nest JS',
  Java = 'Java',
  Go = 'GO',
  Kotlin = 'Kotlin',
  SpringBoot = 'Spring Boot',
  Docker = 'Docker',
  AWS_ECS = 'AWS ECS',
  Nginx = 'Nginx',
  Kubernetes = 'K8s',
  RabbitMQ = 'RabbitMQ',
  OpenAI = 'OpenAI',
  Ffmpeg = 'FFmpeg',
}

const TechBadge = [
  {
    name: TechTags.ReactJs,
    color: 'cyan',
  },
  {
    name: TechTags.NestJs,
    color: 'red',
  },
  {
    name: TechTags.SpringBoot,
    color: 'green',
  },
  {
    name: TechTags.Java,
    color: 'green',
  },
  {
    name: TechTags.Kotlin,
    color: 'violet',
  },
  {
    name: TechTags.Docker,
    color: 'blue',
  },
  {
    name: TechTags.AWS_ECS,
    color: 'orange',
  },
  {
    name: TechTags.RabbitMQ,
    color: 'orange',
  },
];

export type Project = {
  id: string;
  title: string;
  tag: TechTags[];
  thumbnail: string | null;
  date: string;
  description: string;
  details: string;
  isFeatured?: boolean;
  isSideProject?: boolean;
  doc?: string;
  demo?: string;
  badge?: string[];
  images?: {
    src: string;
    desc?: string;
  }[];
  github?: string;
};

const projects: Project[] = [
  {
    id: 'chatGPT-cli',
    title: 'ChatGPT CLI',
    description: 'A client implementation for chatGPT using openAI api and Go',
    tag: [TechTags.Go, TechTags.OpenAI, TechTags.Ffmpeg],
    date: '2023',
    thumbnail:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/512px-ChatGPT_logo.svg.png',
    details: 'A CLI tool allow user to chat with GPT in terminal',
    badge: ['NEW'],
    isFeatured: true,
    doc: 'gpt-cli.md',
    github: 'https://github.com/pang0103/go-chatgpt-cli',
    isSideProject: true,
  },
  {
    id: 'hhp',
    title: 'Housing Monitor',
    description:
      'Rental property monitoring service, now available in Netherlands',
    tag: [
      TechTags.ReactJs,
      TechTags.TypeScript,
      TechTags.Java,
      TechTags.SpringBoot,
    ],
    date: '2023',
    thumbnail:
      'https://cdn.discordapp.com/attachments/343752598869901315/1098337160408596552/logo-icon.png',
    details: `It monitors the rental market and notify user when a new rental property is available.`,
    badge: ['NEW'],
    demo: 'https://househunterpro.nl/',
    images: [
      {
        src: 'https://i.postimg.cc/Cx213vxt/SCR-20230420-lozg.png',
        desc: 'User could see the latest rental property',
      },
      {
        src: 'https://i.postimg.cc/V66KJk85/SCR-20230420-lqdx.png',
        desc: 'Main page',
      },
      {
        src: 'https://i.postimg.cc/9F3sny2p/SCR-20230420-lqfa.png',
        desc: 'User could subscibe to the notification service to get notified when a new rental property is available',
      },
    ],
    doc: 'hhp.md',
    isFeatured: true,
    isSideProject: true,
  },
  {
    id: 'card-plus',
    title: 'DBS Card+',
    description:
      'Mobile application for credit card holders, responsible for microservies development',
    details:
      'Involves in the development of various backend microservices, including the payment reminder service, bill payment service',
    tag: [
      TechTags.Java,
      TechTags.Kotlin,
      TechTags.SpringBoot,
      TechTags.Kubernetes,
    ],
    date: '2022',
    thumbnail:
      'https://is1-ssl.mzstatic.com/image/thumb/Purple128/v4/fd/05/ae/fd05ae7b-19d0-54f0-50ba-b9c6dc369750/source/512x512bb.jpg',
    isFeatured: true,
    doc: 'cardplus.md',
  },
  {
    id: 'bank-loan-faas',
    title: 'DBS Financing',
    description:
      'Web application connecting policyholders and Premium Financing Specialists about insurance funding arragement',
    thumbnail:
      'https://sheikhmohammedirfan.com/wp-content/uploads/2017/10/dbs-bank-logo.png',
    tag: [TechTags.NestJs, TechTags.ReactJs, TechTags.AWS_ECS, TechTags.Docker],
    date: '2022',
    details: `DBS Financing targeted to provide a smooth and secure loan & insurance funding service to customer.
      Customer can apply for loan and insurance funding through the web application.
    `,
    demo: 'https://financing.dbs.com.hk/homepage',
    doc: 'trinity.md',
    images: [
      {
        src: 'https://i.postimg.cc/cJfmSWts/SCR-20230420-owzs.jpg',
      },
      {
        src: 'https://i.postimg.cc/KzND155w/SCR-20230420-skbv.png',
      },
      {
        src: 'https://i.postimg.cc/brV0TNWR/SCR-20230420-skee.png',
      },
    ],
    isFeatured: true,
  },
  {
    id: 'messaging-and-location-portal',
    title: 'Messaging and Location Portal',
    description:
      'A SMS system for sending messages and location updates to users',
    thumbnail:
      'https://media.licdn.com/dms/image/C4D0BAQE01RrrkwbUow/company-logo_200_200/0/1519856360351?e=2147483647&v=beta&t=I0iWm3tUiw8ggmIrS1qRFrP7oG3i-ss-OXcPnF4Pvwk',
    tag: [
      TechTags.Java,
      TechTags.SpringBoot,
      TechTags.Docker,
      TechTags.Kubernetes,
      TechTags.RabbitMQ,
    ],
    date: '2021',
    details: 'A SMS system for sending messages and location updates to users',
    isFeatured: true,
    doc: 'mlp.md',
  },
  {
    id: 'general-data-migration-tool',
    title: 'General data migration tool',
    description: 'An internal data migration tool',
    tag: [TechTags.SpringBoot, TechTags.Java],
    thumbnail:
      'https://media.licdn.com/dms/image/C4D0BAQE01RrrkwbUow/company-logo_200_200/0/1519856360351?e=2147483647&v=beta&t=I0iWm3tUiw8ggmIrS1qRFrP7oG3i-ss-OXcPnF4Pvwk',
    date: '2021',
    details:
      'Allow Programmer to implement and customize the tool according to their business logic.',
    isFeatured: true,
    doc: 'migr.md',
  },
  {
    id: 'cryptichat-app',
    title: 'CryptiChat',
    description:
      'An anonymous messaging app for users to create private chat room',
    thumbnail: 'https://i.postimg.cc/k5gf0Ln0/chatapp-3.png',
    tag: [
      TechTags.ReactJs,
      TechTags.Java,
      TechTags.SpringBoot,
      TechTags.Docker,
      TechTags.Nginx,
    ],
    date: '2021',
    details: 'An anonymous messaging app for users to create private chat room',
    demo: 'http://chat.projects.johnnychiu.info/',
    isFeatured: true,
    doc: 'crypti-chat.md',
    github: 'https://github.com/pang0103/cryptichat-service',
    images: [
      // https://postimg.cc/gallery/DXvfkPY/f65b939d
      {
        src: 'https://i.postimg.cc/52TXJC1f/SCR-20230424-prih.png',
        desc: "CryptiChat's login page",
      },
      {
        src: 'https://i.postimg.cc/PqDP55rY/SCR-20230424-prjo.png',
        desc: 'There is a guest mode for user to try out the app',
      },
      {
        src: 'https://i.postimg.cc/hvDjs881/SCR-20230424-prmw.png',
        desc: 'User can create a chat room and share the room code to others',
      },
      {
        src: 'https://i.postimg.cc/rsfpPdQX/SCR-20230424-prqt.png',
        desc: 'User can accept or reject the invitation to join the chat room',
      },
      {
        src: 'https://i.postimg.cc/DZd01pGz/SCR-20230424-prsv.png',
        desc: 'User can see the other user in typing status',
      },
      {
        src: 'https://i.postimg.cc/mDGhzWtq/SCR-20230424-prwi.png',
        desc: 'Text message and emoji are supported',
      },
      {
        src: 'https://i.postimg.cc/QxMCWbdX/SCR-20230424-prxh.png',
        desc: 'When one of the users leave the chat room, the chat room will be closed',
      },
    ],
    isSideProject: true,
  },
  {
    id: 'intercloud',
    title: 'Security for Mobile Intercloud',
    description: 'Final Year Project @PolyU',
    thumbnail:
      'https://is2-ssl.mzstatic.com/image/thumb/Purple112/v4/76/76/ff/7676ff87-eab2-204b-a42f-61135faf3e4d/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/1024x1024.jpg',
    tag: [TechTags.Java],
    details:
      'To allow the data transfer between mobile and intercloud system, a mobile-Intercloud communication method is designed. For the implementation, the Intercloud Gateway is extended and a package of MIT App Inventor extensions for mobile-Intercloud communication is developed. User can use these extensions to build mobile app to connect with the Intercloud networks and transfer data',
    date: '2020',
  },
];

export { TechBadge, projects, TechTags };
