import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Group,
  Text,
  Image,
  Divider,
  Space,
  Modal,
  Stack,
  Grid,
  rem,
  Button,
  Center,
  Paper,
  TypographyStylesProvider,
  Flex,
  Loader,
} from '@mantine/core';
import { Carousel, Embla, useAnimationOffsetEffect } from '@mantine/carousel';
import MarkDown from 'markdown-to-jsx';
import {
  IconAccessPoint,
  IconBrandGithub,
  IconChevronsLeft,
  IconFileAlert,
  IconNote,
  IconTag,
} from '@tabler/icons-react';
import NotFound from '../NotFound';
import { projects } from './data';
import useMarkdownDocument from '../../hooks/useMarkdownDocument';
import DocumentSkeleton from '../../components/skeleton/DocumentSkeleton';
import ProjectLabel from './ProjectLabel';
import NullImagePlaceHolder from '../../components/logo/NullImagePlaceHolder';
import BadgeList from '../../components/BadgeList';

export default function ProjectDetails() {
  const { projectName } = useParams();

  /**
   * Carousel
   * @See https://mantine.dev/others/carousel/#carousel-container-animation-offset
   */
  const [embla, setEmbla] = useState<Embla | null>(null);
  const TRANSITION_DURATION = 200;
  useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  const project = projects.find((proj) => proj.id === projectName);
  const document = project?.doc;

  const { markdownText, loading, error } = useMarkdownDocument(document);

  const [openedImgViewer, setOpenedImgViewer] = useState(false);

  const [activeImgDesc, setActiveImgDesc] = useState('');

  const navigate = useNavigate();

  if (loading) {
    return <DocumentSkeleton />;
  }

  if (!document || error) {
    return (
      <NotFound
        reason="Document unavailable"
        icon={<IconFileAlert size={80} />}
      />
    );
  }

  return (
    <>
      <Modal
        opened={openedImgViewer}
        onClose={() => setOpenedImgViewer(false)}
        padding={15}
        transitionProps={{ duration: TRANSITION_DURATION }}
        centered
        withCloseButton={false}
        size="80%"
      >
        {project.images && (
          <Grid grow columns={100}>
            <Grid.Col span={70}>
              <Carousel
                withIndicators
                getEmblaApi={setEmbla}
                styles={{
                  indicator: {},
                }}
                onSlideChange={(idx) => {
                  setActiveImgDesc(project.images?.[idx].desc || '');
                }}
              >
                {project.images?.map((img, idx) => (
                  <Carousel.Slide>
                    <Image src={img.src} />
                  </Carousel.Slide>
                ))}
              </Carousel>
            </Grid.Col>
            <Grid.Col span={30}>
              <Stack style={{ display: 'flex', padding: '15px' }}>
                <Flex>
                  <IconTag color="gray" />
                  <Space w="xs" />
                  <Text color="gray" fw={500}>
                    Notes
                  </Text>
                </Flex>

                <Divider />
                <Text size="sm">{activeImgDesc}</Text>
              </Stack>
            </Grid.Col>
          </Grid>
        )}
      </Modal>

      {project.images && (
        <Paper shadow="sm" radius="lg" p="md">
          <Carousel
            withIndicators
            height={200}
            slideSize="33.333333%"
            slideGap="md"
            loop
            align="start"
            breakpoints={[
              { maxWidth: 'md', slideSize: '50%' },
              { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
            ]}
          >
            {project.images?.map((img, idx) => {
              return (
                <Carousel.Slide key={idx}>
                  <Image
                    style={{ cursor: 'pointer' }}
                    radius="md"
                    src={img.src}
                    alt="Random unsplash image"
                    onClick={() => setOpenedImgViewer(true)}
                  />
                </Carousel.Slide>
              );
            })}
          </Carousel>
        </Paper>
      )}

      <Space h="md" />
      <Paper shadow="sm" radius="lg" p="md">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Group>
            <Avatar src={project.thumbnail} size="lg">
              {!project.thumbnail && <NullImagePlaceHolder />}
            </Avatar>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text fw={700} size={24}>
                {project.title}
              </Text>
              <BadgeList badgeList={project.tag} />
            </div>
          </Group>
          <Group>
            {project.github && (
              <Center>
                <a href={project.github} target="_blank" rel="noreferrer">
                  <Button
                    variant="default"
                    color="gray"
                    leftIcon={<IconBrandGithub />}
                  >
                    Source Code
                  </Button>
                </a>
              </Center>
            )}
            {project.demo && (
              <Center>
                <a href={project.demo} target="_blank" rel="noreferrer">
                  <Button
                    variant="light"
                    rightIcon={<Loader size="xs" variant="bars" />}
                  >
                    Live Demo
                  </Button>
                </a>
              </Center>
            )}
          </Group>
        </div>
        <Divider my="sm" variant="dashed" />

        <article>
          <Text
            style={{
              margin: '15px',
              marginTop: '30px',
            }}
          >
            <TypographyStylesProvider>
              <MarkDown>{markdownText}</MarkDown>
            </TypographyStylesProvider>
          </Text>
        </article>
      </Paper>
      <Space h="md" />

      <Paper shadow="sm" radius="lg" p="md" mt="10px">
        <Center>
          <Button
            onClick={() => navigate('/projects')}
            variant="subtle"
            leftIcon={<IconChevronsLeft />}
          >
            All Projects
          </Button>
        </Center>
      </Paper>
    </>
  );
}
