import React from 'react';
import { Image } from '@mantine/core';

export default function NullImagePlaceHolder() {
  return (
    <Image
      width={200}
      height={120}
      src={null}
      alt="With default placeholder"
      withPlaceholder
    />
  );
}
