import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Center,
  Collapse,
  createStyles,
  Divider,
  Flex,
  Group,
  Indicator,
  Paper,
  Spoiler,
  Text,
} from '@mantine/core';
import ReactMarkdown from 'react-markdown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  IconChevronsLeft,
  IconChevronsRight,
  IconDots,
  IconFoldDown,
  IconLayoutList,
} from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import AvatarCard from '../components/AvatarCard';
import Projects from './projects/Projects';
import ProjectLabel from './projects/ProjectLabel';
import { Project, projects } from './projects/data';
import ProjectPaperItem from '../components/ProjectPaperItem';

const content1 = `# 👋🏻 Hello there! 

I'm a Software engineer based in Toronto, ON 🇨🇦. 

Backend development is my area of expertise, especially in the field of **Java**, **JVM**, **Microservices** and **Distributed System**.

- I mainly write **Java** (8+), **Kotlin** and **TypeScript** in my daily work.

- I am familiar with **Spring Boot** & **NestJs** 

- Currently, I'm learning **Go**.

- I'm an enthusiastic team player who would love to work with you. 😎

`;

const content2 = `## Why I came to Canada ? 
I was born and raised in Hong Kong, but recently decided to move to Canada for a new chapter in my life. As a software engineer, I am constantly seeking opportunities to grow and learn, and Canada offers a wealth of opportunities in the tech industry. Toronto, in particular, is a hub for innovation and technology, making it an ideal location for me to pursue my passion for software development.
`;

const content3 = `## What I do in Hong Kong ? 
In Hong Kong, I worked as a software developer, primarily utilizing JVM languages such as Java, Kotlin, and some Scala to build backend services. I have experience in developing telecommunications and finance products. I am a continuous learner and am currently exploring Rust, as I find it to be an interesting language for performance-critical programs.

I am seeking job opportunities in Toronto and would be happy to provide my resume. If you think I would be a good fit for your team, please feel free to contact me. I am eager to hear from you and look forward to the possibility of working together.
`;

const useStyles = createStyles((theme) => ({
  introWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing.md,
    marginBottom: theme.spacing.md,
    // margin: theme.spacing.md,

    [theme.fn.smallerThan(770)]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },

  introBox: {
    margin: theme.spacing.xs,
  },

  projectCardContainer: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.fn.smallerThan(770)]: {
      flexDirection: 'column-reverse',
    },
  },

  projectCard: {
    width: '50%',

    [theme.fn.smallerThan(770)]: {
      width: '100%',
    },
  },
}));

interface ProjectLinkProps {
  proj: Project;
  children: React.ReactNode;
}

function ProjectLink(props: ProjectLinkProps) {
  const { proj } = props;
  const { children } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    // navigate(`/projects?p=${proj.id}`);
    navigate(`/projects/${proj.id}`);
  };

  return (
    <Box
      onClick={handleClick}
      style={{ textDecoration: 'none', cursor: 'pointer' }}
    >
      {children}
    </Box>
  );
}

export default function About() {
  const { classes } = useStyles();
  const { pathname } = useLocation();

  const [opened, { toggle }] = useDisclosure(false);

  return (
    <>
      <div className={classes.introWrapper}>
        <div className={classes.introBox}>
          <ReactMarkdown children={content1} />
        </div>
        <AvatarCard />
      </div>
      <Box mx="auto">
        <Group position="center" mb={5}>
          <Button
            fullWidth
            variant="subtle"
            color="gray"
            onClick={toggle}
            leftIcon={<IconChevronsRight />}
            rightIcon={<IconChevronsLeft />}
          >
            Know more about me
          </Button>
        </Group>
        <Collapse in={opened}>
          <ReactMarkdown children={content2} />
          <ReactMarkdown children={content3} />
        </Collapse>
      </Box>
      <Divider my="md" />
      <Box className={classes.projectCardContainer}>
        <Box className={classes.projectCard}>
          <Center>
            <Text size={22} weight={700} mt="md" mb="md">
              👨🏻‍💻 Professional Experiences
            </Text>
          </Center>
          {projects
            .filter(
              (proj) =>
                proj.isSideProject === false ||
                proj.isSideProject === undefined,
            )
            .map((proj) => (
              <ProjectLink proj={proj}>
                <ProjectPaperItem proj={proj} />
              </ProjectLink>
            ))}
        </Box>
        <Box className={classes.projectCard}>
          <Center>
            <Text size={22} weight={700} mt="md" mb="md">
              🎨 Side Projects
            </Text>
          </Center>
          {projects
            .filter((proj) => proj.isSideProject === true)
            .map((proj) => (
              <ProjectLink proj={proj}>
                <ProjectPaperItem proj={proj} />
              </ProjectLink>
            ))}
        </Box>
      </Box>
    </>
  );
}
