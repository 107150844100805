import React from 'react';
import { ActionIcon, Text, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconCopy } from '@tabler/icons-react';

export default function ClipboardCopy({
  text,
  title,
}: {
  text: string;
  title: string;
}) {
  const [copied, setCopied] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [tooltipMessage, setTooltipMessage] = React.useState('copy');
  const copyToClipboard = () => {
    if (!copied) {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTooltipMessage('copied');
      showNotification({
        id: 'copy-to-clipboard',
        message: 'Copied to clipboard',
        autoClose: 2000,
        withCloseButton: false,
        color: 'green',
        styles: (theme) => ({
          title: { color: theme.colors.dark[1] },
        }),
      });
      setTimeout(() => {
        setTooltipMessage('copy');
        setCopied(false);
      }, 100000);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text weight={700}>{title}:&nbsp;</Text>
      <Text>{text}</Text>
      <Tooltip
        label={tooltipMessage}
        opened={tooltipOpen}
        position="right"
        withArrow
        transitionProps={{ transition: 'rotate-right' }}
      >
        <ActionIcon
          style={{ marginLeft: '10px' }}
          size="xs"
          onClick={copyToClipboard}
          onMouseEnter={() =>
            setTimeout(() => {
              setTooltipOpen(true);
            }, 500)
          }
          onMouseLeave={() =>
            setTimeout(() => {
              setTooltipOpen(false);
            }, 500)
          }
        >
          {copied ? <IconCheck color="green" /> : <IconCopy />}
        </ActionIcon>
      </Tooltip>
    </div>
  );
}
