import React from 'react';
import { Footer, Center, Text, createStyles } from '@mantine/core';

const useStyles = createStyles((theme, _params, getRef) => ({
  footer: {
    maxWidth: '900px',
    margin: 'auto',
  },
}));

export default function NavFooter() {
  const { classes } = useStyles();

  return (
    <Footer className={classes.footer} height={60} p="md">
      <Center>
        <Text size="xs">
          Built with passion using React and Mantine.{' '}
          <a href="https://github.com/pang0103/personal-web">Github</a>
        </Text>
      </Center>
    </Footer>
  );
}
